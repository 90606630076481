import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/molecules/NavBar/NavBar';
import HomeContent from '../components/organisms/HomeContent/HomeContent';
import AboutSection from '../components/organisms/AboutSection/AboutSection';
import OfferSection from '../components/organisms/OfferSection/OfferSection';
import ContactSection from '../components/organisms/ContactSection/ContactSection';
import MapSection from '../components/organisms/MapSection/MapSection';
import Footer from '../components/organisms/Footer/Footer';

const StyledWrapper = styled.div`
  height: 100vh;
  position: relative;
`;

const HomeView = () => (
  <>
    <StyledWrapper>
      <NavBar />
      <HomeContent />
    </StyledWrapper>
    <AboutSection />
    <OfferSection />
    <ContactSection />
    <MapSection />
    <Footer/>
  </>
);

export default HomeView;
