import React from 'react';
import styled, { withTheme } from 'styled-components';
import Paragraph from '../Paragraph/Paragraph';
import Heading from '../Heading/Heading';
import useMedia from 'use-media';

const AboutWrapper = styled.div`
  width: ${({ isMobile }) => (isMobile ? '80vw' : '450px')};
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 6px;
  padding: 15px;
  img {
    width: 40%;
  }

  h3 {
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.font.bold};
  }
`;

const StyledIcon = styled.div`
  height: ${({ isMobile }) => (isMobile ? 'auto' : '250px')};
  padding: ${({ isMobile }) => (isMobile ? '5px' : '20px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTextWrapper = styled.div`
  padding: ${({ isMobile }) => (isMobile ? '5px' : '20px')};
  text-align: center;
`;

const StyledHeading = styled(Heading)`
  font-weight: ${({ theme }) => theme.font.bold};
  font-size: 120px;
  line-height: ${({ isMobile }) => isMobile && '20vh'};
  margin: 0;
  color: ${({ theme }) => theme.yellow};
`;

const AboutTile = ({ theme, icon, title, text, heading }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });

  return (
    <AboutWrapper isMobile={isMobile}>
      <StyledIcon isMobile={isMobile}>
        {heading ? (
          <StyledHeading isMobile={isMobile}>30</StyledHeading>
        ) : (
          <img src={icon} alt="icon" />
        )}
      </StyledIcon>
      <StyledTextWrapper isMobile={isMobile}>
        <h3>{title}</h3>
        <Paragraph>{text}</Paragraph>
      </StyledTextWrapper>
    </AboutWrapper>
  );
};

export default withTheme(AboutTile);
