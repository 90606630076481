import React from 'react';
import styled, { withTheme } from 'styled-components';
import useMedia from 'use-media';
import contact from 'assets/images/contact.jpg';
import Heading from '../../atoms/Heading/Heading';
import Paragraph from '../../atoms/Paragraph/Paragraph';

const ContactWrapper = styled.div`
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  background: ${({ isMobile }) => (isMobile ? 'white' : `url(${contact}) no-repeat center`)};
  background-size: cover;
  padding: 20px 40px;
  justify-content: center;
  h1 {
    font-size: 3rem;
  }
`;
const ContactInfoWrapper = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  margin: 10px;

  h2 {
    font-size: ${({ isMobile }) => (isMobile ? '2rem' : '3rem')};
    margin: 10px;
  }
  p {
    font-size: ${({ isMobile }) => (isMobile ? '2rem' : '3rem')};
  }
  a {
    text-decoration: none;
    color: black;
  }
`;
const YellowOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  background-color: rgba(242, 200, 57, 0.79);

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    display: none;
  }
`;

const StyledInfoBox = styled.div`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'space-between' : 'left')};
  align-items: center;
`;

const StyledA = styled.a`
  color: ${({ theme }) => theme.darkGrey};
`;

const ContactInfo = ({ theme }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });

  return (
    <ContactWrapper isMobile={isMobile}>
      <ContactInfoWrapper isMobile={isMobile}>
        <div>
          <Heading>Skontakuj się z nami przez telefon, maila lub wypełniając formularz</Heading>
        </div>
        <StyledInfoBox>
          <h2>Telefon</h2>
          <Paragraph>
            <StyledA href="tel:505 120 795">505 120 795</StyledA>
          </Paragraph>
        </StyledInfoBox>
        <StyledInfoBox>
          <h2>Tel/Fax</h2>
          <Paragraph>
            <StyledA href="tel:34 353 00 83">34 353 00 83</StyledA>
          </Paragraph>
        </StyledInfoBox>
        <StyledInfoBox>
          <h2>Mail</h2>
          <Paragraph>
            <StyledA href="mailto:muratex@vp.pl">muratex@vp.pl</StyledA>
          </Paragraph>
        </StyledInfoBox>
        <StyledInfoBox>
          <h2>NIP</h2>
          <Paragraph>5750005435</Paragraph>
        </StyledInfoBox>
        <StyledInfoBox>
          <h2>REGON</h2>
          <Paragraph>150049754</Paragraph>
        </StyledInfoBox>
      </ContactInfoWrapper>
      <YellowOverlay />
    </ContactWrapper>
  );
};

export default withTheme(ContactInfo);
