import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import MainTemplate from '../templates/MainTemplate';
import { AnimatePresence } from 'framer-motion';
import routes from '../routes';
import HomeView from './HomeView';
import GalleryView from './GalleryView';
import { Provider } from 'react-redux';
import store from '../redux/store/store';

const App = () => (
  <Provider store={store}>
    <Router>
      <Route
        render={({ location }) => (
          <AnimatePresence exitBeforeEnter initial={false}>
            <MainTemplate path={location.pathname}>
              <Switch location={location}>
                <Route exact path={routes.root} render={() => <Redirect to={routes.home}/>}/>
                <Route exact path={routes.home} component={HomeView}/>
                <Route exact path={routes.gallery} component={GalleryView}/>
              </Switch>
            </MainTemplate>
          </AnimatePresence>
        )}
      />
    </Router>
  </Provider>
);

export default App;
