export const PROJECTS_PENDING = 'PROJECTS_PENDING';
export const PROJECTS_SUCCESS = 'PROJECTS_SUCCESS';
export const PROJECTS_FAILURE = 'PROJECTS_FAILURE';
export const PROJECTS_UPDATE_IMAGE = 'PROJECTS_UPDATE_IMAGE';

export const projectsPending = () => ({
    type: PROJECTS_PENDING
});

export const projectsSuccess = () => ({
    type: PROJECTS_SUCCESS
});

export const projectsFailure = errorMessage => ({
    type: PROJECTS_FAILURE,
    payload: errorMessage
});

export const projectsUpdateImage = (image, projectName) => ({
    type: PROJECTS_UPDATE_IMAGE,
    payload: {
        image,
        projectName
    }
});