import React from 'react';
import styled from 'styled-components';

const InputWrapper = styled.input`
  margin: 10px;
  width: ${({ isMobile }) => isMobile ? '90vw' : '383px'};
  height: 41px;
  border: none;
  border-radius: 8px;
  padding: 15px;
`;

const TextareaWrapper = styled.textarea`
  margin: 10px;
  border: none;
  width: ${({ isMobile }) => isMobile && '90vw'};
  border-radius: 8px;
  padding: 15px;
  resize: none;
  overflow: hidden;
`;

const Input = ({ title, area, onChange, name, isMobile }) =>
  area ? (
    <TextareaWrapper isMobile={isMobile} placeholder={title} rows="5" cols="48" onChange={onChange} name={name}/>
  ) : (
    <InputWrapper isMobile={isMobile} placeholder={title} onChange={onChange} name={name}/>
  );

export default Input;
