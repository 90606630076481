import React from 'react';
import styled from 'styled-components';

const StyledMenu = styled.a`
  text-decoration: none;
  font-weight: ${({ theme }) => theme.font.normal};
  font-size: ${({ theme }) => theme.font.small};
  color: ${({ theme }) => theme.darkGrey};

  :hover {
    cursor: pointer;
  }
  :visited {
    font-weight: ${({ theme }) => theme.font.bold};
  }
`;

export default StyledMenu;
