import firebase from "firebase";
const firebaseConfig = {
    apiKey: "AIzaSyBvVFH_0YTg0A5SWzWkMW1HN06LUoix0kg",
    authDomain: "zbrm-struzyk.firebaseapp.com",
    databaseURL: "https://zbrm-struzyk.firebaseio.com",
    projectId: "zbrm-struzyk",
    storageBucket: "zbrm-struzyk.appspot.com",
    messagingSenderId: "231613391315",
    appId: "1:231613391315:web:f217a86f4738f9a118cf26",
    measurementId: "G-H9JVZ24ZEP"
};

firebase.initializeApp(firebaseConfig);

export default firebase;