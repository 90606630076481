import React from 'react';
import styled, { withTheme } from 'styled-components';
import OfferTile from 'components/atoms/OfferTile/OfferTile';
import data from 'assets/data/offer';
import SectionHeading from '../../molecules/SectionHeading/SectionHeading';
import useMedia from 'use-media';

const OfferWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledGridWrapper = styled.div`
  display: grid;
  grid-gap: ${({ isMobile }) => isMobile ? '4vw' : '50px'};
  grid-template-columns: repeat(${({ isMobile }) => isMobile ? 2 : 3}, 1fr);
  grid-template-rows: repeat(${({ isMobile }) => isMobile ? -1 : 2}, 1fr);
`;

const OfferSection = ({ theme }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });

  return (
    <OfferWrapper>
      <SectionHeading>oferujemy</SectionHeading>
      <StyledGridWrapper isMobile={isMobile}>
        {
          data.map((item) => (
            <OfferTile {...item} />
          ))
        }
      </StyledGridWrapper>
    </OfferWrapper>
  );
};

export default withTheme(OfferSection);
