import React from "react";
import GlobalStyles from "../themes/GlobalStyles";
import theme from "../themes/MainTheme";
import {ThemeProvider} from "styled-components";

const MainTemplate = ({children}) => (
    <>
        <GlobalStyles/>
        <ThemeProvider theme={theme}>
            {
                children
            }
        </ThemeProvider>
    </>
);

export default MainTemplate;