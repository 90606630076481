import React from 'react';
import styled, { withTheme } from 'styled-components';
import Iframe from 'react-iframe';
import MapInfo from '../../atoms/MapInfo/MapInfo';
import SectionHeading from '../../molecules/SectionHeading/SectionHeading';
import useMedia from 'use-media';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const MapWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const MapDiv = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;

const MapSection = ({ theme }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });

  return (
    <MapWrapper id={'adres'}>
      <StyledWrapper>
        <SectionHeading>jak dojechać</SectionHeading>
      </StyledWrapper>
      <MapDiv isMobile={isMobile}>
        <MapInfo />
        <Iframe
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2527.376665893249!2d18.71486131590577!3d50.69439437763803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4710c33d6dd7641d%3A0x9c3494306c1db99f!2sLe%C5%9Bna%2020A%2C%2042-700%20Jawornica!5e0!3m2!1spl!2spl!4v1638971764700!5m2!1spl!2spl"
          width="100%"
          height="628px"
          display="initial"
          loading="lazy"
        />
      </MapDiv>
    </MapWrapper>
  );
};

export default withTheme(MapSection);
