import React, { useEffect } from 'react';
import styled, { withTheme } from 'styled-components';
import NavBar from '../components/molecules/NavBar/NavBar';
import SectionHeading from '../components/molecules/SectionHeading/SectionHeading';
import { fetchProjects as fetchProjectsAction } from '../redux/actions/projects/fetchProjects';
import { getProjects, getError, getPending } from '../redux/reducers/projectsReducer';
import { connect } from 'react-redux';
import GalleryBox from '../components/molecules/GalleryBox/GalleryBox';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import useMedia from 'use-media';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
`;

const GalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: ${({ isMobile }) => (isMobile ? '5px' : '50px')};
`;

const StyledSubtitle = styled.h2`
  font-variant: small-caps;
  text-transform: uppercase;
  color: ${({ theme }) => theme.darkGrey};
  text-align: center;
`;

const GalleryView = ({ theme, fetchProjects, projects, pending, error }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });
  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <>
      <NavBar/>
      <StyledWrapper>
        <SectionHeading>galeria</SectionHeading>
      </StyledWrapper>
      {error && <div>Nie udało się załadować zdjęć</div>}

      {pending && (
        <StyledWrapper>
          <Loader type="TailSpin" color="#F2C839" timeout={3000} height={150} width={150}/>
        </StyledWrapper>
      )}

      <StyledSubtitle>Przykładowe realizacje</StyledSubtitle>

      {!pending && !error && (
        <GalleryWrapper isMobile={isMobile}>
          {
            Object.keys(projects).sort().map((key) => (
              <GalleryBox isMobile={isMobile} title={key} images={projects[key]}/>
            ))
          }
        </GalleryWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  projects: getProjects(state),
  error: getError(state),
  pending: getPending(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjects: () => dispatch(fetchProjectsAction()),
});

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(GalleryView));
