import React from 'react';
import styled from 'styled-components';
import MenuElement from '../../atoms/MenuElement/MenuElement';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import fb from 'assets/icons/fb.png';
import routes from '../../../routes';
import { Link } from 'react-router-dom';

const StyledMenuWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100vw;
  z-index: -1;
  background-color: white;
`;

const StyledAnchorLink = styled(AnchorLink)`
  text-decoration: none;
  margin: 30px 0 ;
`;

const StyledSocials = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 10px;
`;

const StyledFb = styled.img`
  width: 40px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 30px 0 ;
`

const Menu = ({ toggleMenuOpen }) => (
  <StyledMenuWrapper>
    {
      ['O NAS', 'KONTAKT', 'ADRES'].map((item) => (
        <StyledAnchorLink href={`#${item.toLocaleLowerCase()
          .replace(' ', '_')}`}>
          <MenuElement onClick={() => toggleMenuOpen(false)}>{item}</MenuElement>
        </StyledAnchorLink>
      ))
    }
    <StyledLink to={routes.gallery}>
      <MenuElement onClick={() => toggleMenuOpen(false)}>GALERIA</MenuElement>
    </StyledLink>
    <StyledSocials>
      <a href="https://www.facebook.com/" onClick={() => toggleMenuOpen(false)}>
        <StyledFb src={fb} alt=""/>
      </a>
      <b>+48 34 353 00 83</b>
    </StyledSocials>

  </StyledMenuWrapper>
);

export default Menu;