const theme = {
  yellow: '#F2C839',
  lightGrey: '#E0E0E0',
  darkGrey: '#262829',
  media: {
    mobile: '1024px',
  },
  font: {
    small: '1.6rem',
    mediumSize: '2rem',
    subHeading: '4.3rem',
    mobileSubHeading: 'calc(2rem + 3.125vw)',
    heading: '6rem',
    heroHeading: '14rem',
    mobileHeroHeading: 'calc(8rem + 3.125vw)',
    bold: '700',
    normal: '400',
    medium: '500',
  },
};

export default theme;
