import React from 'react';
import styled, { withTheme, css } from 'styled-components';
import slider1 from 'assets/images/slider/slider1.png';
import slider2 from 'assets/images/slider/slider2.png';
import slider3 from 'assets/images/slider/slider3.png';
import slider4 from 'assets/images/slider/slider4.png';
import slider5 from 'assets/images/slider/slider5.png';
import HeroLogoBlock from 'components/molecules/HeroLogoBlock/HeroLogoBlock';
import Paragraph from '../../atoms/Paragraph/Paragraph';
import Button from '../../atoms/Button/Button';
import routes from '../../../routes';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import useMedia from 'use-media';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const StyledWrapper = styled.div`
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
`;

const AlignWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

`;

const StyledParagraph = styled(Paragraph)`
  width: 700px;
  margin-top: 0;
  line-height: 175%;
  text-align: left;
`;

const StyledButton = styled(Button)`
  margin: 0 30px 0 0;
`;

const StyledTextBox = styled.div`
  margin-left: 3%;
`;

const StyledAnchorLink = styled(AnchorLink)`
  text-decoration: none;
`;

const StyledButtonWrapper = styled.div`
  margin-top: ${({ isMobile }) => isMobile && '30px'};
`;

const AutoplaySlider = withAutoplay(AwesomeSlider);

const StyledSlider = styled(AutoplaySlider)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
`;

const HomeContent = ({ theme }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });

  return (
    <StyledWrapper>
      <StyledSlider
        organicArrows={false}
        bullets={false}
        play={true}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={2000}
      >
        <div data-src={slider1} />
        <div data-src={slider2} />
        <div data-src={slider3} />
        <div data-src={slider4} />
        <div data-src={slider5} />
      </StyledSlider>
      <AlignWrapper isMobile={isMobile}>
        <HeroLogoBlock isMobile={isMobile} />
        <StyledTextBox>
          <StyledButtonWrapper isMobile={isMobile}>
            <StyledAnchorLink href={'#kontakt'}>
              <StyledButton isMobile={isMobile}>Kontakt</StyledButton>
            </StyledAnchorLink>
            <Link to={routes.gallery}>
              <StyledButton isMobile={isMobile} secondary>
                galeria
              </StyledButton>
            </Link>
          </StyledButtonWrapper>
        </StyledTextBox>
      </AlignWrapper>
    </StyledWrapper>
  );
};

export default withTheme(HomeContent);
