import React from 'react';
import styled, { withTheme } from 'styled-components';
import useMedia from 'use-media';

const SectionWrapper = styled.div`
  position: relative;
  text-transform: uppercase;
  margin: 30px 0;
`;

const StyledHeading = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.darkGrey}; 
  font-weight: ${({ theme }) => theme.font.bold};
  font-size: ${({ theme, isMobile }) => isMobile ? theme.font.mobileSubHeading : theme.font.heading};
`;

const StyledUnderline = styled.div`
  position: absolute;
  left: 10%;
  height: 12%;
  width: 100%;
  background-color: ${({ theme }) => theme.yellow}; 
  bottom: 0;
`;



const SectionHeading = ({ children, theme }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });

  return (
    <SectionWrapper>
      <StyledHeading isMobile={isMobile}>
        {
          children
        }
      </StyledHeading>
      <StyledUnderline/>
    </SectionWrapper>
  );
};

export default withTheme(SectionHeading);
