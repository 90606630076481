import React from 'react';
import styled, { withTheme } from 'styled-components';
import useMedia from 'use-media';

const OfferWrapper = styled.div`
  width: ${({ isMobile }) => (isMobile ? '40vw' : '320px')};
  height: ${({ isMobile }) => (isMobile ? '40vw' : '320px')};
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white no-repeat center url("${({ bg }) => bg}");
  background-size: cover;
  -webkit-box-shadow: 10px 10px 98px -44px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 98px -44px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 98px -44px rgba(0, 0, 0, 0.75);
`;

const StyledImage = styled.img`
  width: 40%;
  z-index: 1;
`;

const StyledTitle = styled.h4`
  text-align: center;
  text-transform: uppercase;
  font-size: ${({ isMobile, theme }) => (isMobile ? '2vw' : theme.font.small)};
  color: white;
  z-index: 1;
  padding: 10px;
`;

const Darkener = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.75);
`;

const OfferTile = ({ title, icon, bg, theme }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });

  return (
    <OfferWrapper bg={bg} isMobile={isMobile}>
      <StyledImage src={icon} alt={title} />
      <StyledTitle isMobile={isMobile}>{title}</StyledTitle>
      <Darkener />
    </OfferWrapper>
  );
};

export default withTheme(OfferTile);
