import React from 'react';
import styled, { withTheme } from 'styled-components';
import Button from '../../../components/atoms/Button/Button';
import useMedia from 'use-media';

const MapInfoWrapper = styled.div`
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
  /* height: 628px; */
  background-color: ${({ theme }) => theme.darkGrey};
  color: white;
  display: flex;
  flex-direction: column;
  padding: 40px;
  justify-content: center;
  align-items: center;
`;

const MapInfo = ({ theme }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });
  return (
    <MapInfoWrapper isMobile={isMobile}>
      <h1>Znajdź nas</h1>
      <p>Ul. Leśna 20A</p>
      <p>42-700 Jawornica</p>
      <p>Śląskie Polska</p>
      <a
        href="https://goo.gl/maps/FBf7phH3hfiycyLM7"
        target="blank"
      >
        <Button>Prowadź</Button>
      </a>
    </MapInfoWrapper>
  );
};

export default withTheme(MapInfo);
