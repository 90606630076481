import React from 'react';
import styled from 'styled-components';
import Gallery from 'react-grid-gallery';

const StyledHeading = styled.h2`
  color: ${({ theme }) => theme.darkGrey};
  text-transform: uppercase;
`;

const GalleryWrapper = styled.div`
  text-align: center;
`;

const StyledImage = styled.img`
  width: 90vw;
`;

const GalleryBox = ({ title, images, isMobile }) => {
  const imageSize = (src) => {
    const image = new Image();
    image.src = src;

    return {
      height: image.height,
      width: window.innerWidth,
    };
  };

  const imagesObjectArray = images.map((image) => {
    const { height, width } = imageSize(image);

    return {
      src: image,
      thumbnail: image,
      thumbnailWidth: width,
      thumbnailHeight: height,
      caption: title,
    };
  });

  return (
    <GalleryWrapper>
      <StyledHeading>{title}</StyledHeading>
      {isMobile ? (
        images.map((image) => <StyledImage src={image} />)
      ) : (
        <Gallery
          showLightboxThumbnails
          imageCountSeparator=" z "
          images={imagesObjectArray}
          backdropClosesModal={true}
          rowHeight={200}
          margin={15}
          enableImageSelection={false}
        />
      )}
    </GalleryWrapper>
  );
};

export default GalleryBox;
