import carpentry from 'assets/icons/carpentry.png';
import carpentryBg from 'assets/images/offer/carpentryBg.png';
import roller from 'assets/icons/roller.png';
import rollerBg from 'assets/images/offer/rollerBg.png';
import steel from 'assets/icons/steel.png';
import steelBg from 'assets/images/offer/steelBg.png';
import home from 'assets/icons/home-run.png';
import homeBg from 'assets/images/offer/homeBg.png';
import school from 'assets/icons/school.png';
import schoolBg from 'assets/images/offer/schoolBg.png';
import roof from 'assets/icons/roof.png';
import roofBg from 'assets/images/offer/roofBg.png';
import work from 'assets/icons/work.png';
import groundBg from 'assets/images/offer/groundBg.png';
import termo from 'assets/icons/termo.png';
import termoBg from 'assets/images/offer/termoBG.png';
import React from 'react';

const offerData = [
  {
    title: 'BUdownictwo ogólne',
    icon: home,
    bg: homeBg,
  },
  {
    title: 'kompletne remonty',
    icon: carpentry,
    bg: carpentryBg,
  },
  {
    title: 'Renowacja obiektów zabytkowych',
    icon: school,
    bg: schoolBg,
  },
  {
    title: 'Termomodernizacja budynków',
    icon: termo,
    bg: termoBg,
  },
  {
    title: 'Roboty wykończeniowe',
    icon: roller,
    bg: rollerBg,
  },
  {
    title: 'roboty dekarskie',
    icon: roof,
    bg: roofBg,
  },
  {
    title: 'wykonanie konstrukcji stalowych',
    icon: steel,
    bg: steelBg,
  },

  {
    title: 'Roboty ziemne',
    icon: work,
    bg: groundBg,
  },
];

export default offerData;
