import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  padding: ${({ isMobile }) => (isMobile ? '10px 25px' : ' 14px 28px')};
  background-color: ${({ theme, secondary }) => (secondary ? theme.lightGrey : theme.yellow)};
  color: ${({ theme }) => theme.darkGrey};
  border-radius: 8px;
  border: none;
  cursor: pointer;
  -webkit-box-shadow: 1px 8px 70px -14px rgba(31, 31, 31, 1);
  -moz-box-shadow: 1px 8px 70px -14px rgba(31, 31, 31, 1);
  box-shadow: 1px 8px 70px -14px rgba(31, 31, 31, 1);
  font-size: ${({ theme }) => theme.font.small};
  font-weight: ${({ theme }) => theme.font.bold};
  text-transform: uppercase;
`;

export default Button;
