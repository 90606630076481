import React, { useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import MenuElement from '../../atoms/MenuElement/MenuElement';
import fb from 'assets/icons/fb.png';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { HamburgerSqueeze } from 'react-animated-burgers';
import useMedia from 'use-media';
import Menu from '../Menu/Menu';
import Logo from '../../atoms/Logo/Logo';

const NavBarWrapper = styled.div`
  position: ${({ isMenuOpen }) => (isMenuOpen ? 'fixed' : 'absolute')};
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 20px;
  z-index: 10000;
  -webkit-box-shadow: 0px 15px 51px 6px rgba(69, 69, 69, 0.3);
  -moz-box-shadow: 0px 15px 51px 6px rgba(69, 69, 69, 0.3);
  box-shadow: 0px 15px 51px 6px rgba(69, 69, 69, 0.3);
  div {
    display: flex;
    align-items: center;
  }
`;

const StyledFb = styled.img`
  width: 40px;
  margin-left: 30px;
`;

const StyledMenuElement = styled(MenuElement)`
  margin: 0 20px;
`;

const StyledAnchorLink = styled(AnchorLink)`
  text-decoration: none;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const NavBar = ({ theme }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });
  const [isMenuOpen, toggleMenuOpen] = useState(false);

  const body = document.querySelector('body');

  useEffect(() => {
    if (isMenuOpen) {
      body.style.overflowY = 'hidden';
    } else {
      body.style.overflowY = 'visible';
    }
  }, [isMenuOpen]);

  return (
    <NavBarWrapper isMenuOpen={isMenuOpen}>
      <Link to={routes.home}>
        <Logo />
      </Link>
      {isMenuOpen && <Menu toggleMenuOpen={toggleMenuOpen} isMenuOpen={isMenuOpen} />}
      {isMobile ? (
        <HamburgerSqueeze isActive={isMenuOpen} toggleButton={() => toggleMenuOpen(!isMenuOpen)} />
      ) : (
        <div>
          {['O NAS', 'KONTAKT', 'ADRES'].map((item) => (
            <StyledAnchorLink href={`#${item.toLocaleLowerCase().replace(' ', '_')}`}>
              <StyledMenuElement>{item}</StyledMenuElement>
            </StyledAnchorLink>
          ))}
          <StyledLink to={routes.gallery}>
            <StyledMenuElement>GALERIA</StyledMenuElement>
          </StyledLink>
          <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/zbrmstruzyk">
            <StyledFb src={fb} alt="facebook" />
          </a>
        </div>
      )}
    </NavBarWrapper>
  );
};

export default withTheme(NavBar);
