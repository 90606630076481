import React from 'react';
import styled, { withTheme } from 'styled-components';
import law from 'assets/icons/law.png';
import house from 'assets/icons/house.png';
import AboutTile from 'components/atoms/AboutTile/AboutTile';
import AboutElement from 'components/atoms/AboutElement/AboutElement';
import aboutimg from 'assets/images/about_image.png';
import SectionHeading from '../../molecules/SectionHeading/SectionHeading';
import useMedia from 'use-media';

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledBackgroundWrapper = styled.div`
  height: 100%;
  background: white url(${aboutimg}) no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: ${({ isMobile }) => isMobile && 'center'};
  flex-direction: ${({ isMobile }) => isMobile && 'column'};
`;

const AboutSection = ({ theme }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });

  return (
    <div id="o_nas">
      <StyledTitleWrapper>
        <SectionHeading>o nas</SectionHeading>
      </StyledTitleWrapper>

      <StyledBackgroundWrapper isMobile={isMobile}>
        <AboutElement />
        {/* <AboutTile
          icon={house}
          title="kompleksowe prace"
          text="Budowlane, remontowe i wykończeniowe. Zapewniamy rzetelną, elastyczną i sprawną obsługę naszych klientów"
        />
        <AboutTile
          heading
          title="Lat doświadczenia"
          text="Zapewniamy rzetelną, elastyczną i sprawną obsługę naszych klientów.
                    Oprócz doświadczenia posiadamy dużą wiedzę i praktykę. Służymy rownież radą w
                    doborze materiałów i zagospodarowaniu przestrzeni.
                    tekst"
        />
        <AboutTile
          icon={law}
          title="UCZCIWOŚĆ I RZETELNOŚĆ"
          text="Zdajemy sobie sprawę z tego, iż chcąc utrzymać naszą pozycje na rynku konieczna jest bezwzględna uczciwość oraz dbałość o wysoką jakość naszych prac ."
        /> */}
      </StyledBackgroundWrapper>
    </div>
  );
};

export default withTheme(AboutSection);
