import React from 'react';
import styled, { withTheme } from 'styled-components';
import useMedia from 'use-media';
import law from 'assets/icons/law.png';
import house from 'assets/icons/house.png';
import AboutTile from 'components/atoms/AboutTile/AboutTile';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
const AboutWrapper = styled.div`
  width: ${({ isMobile }) => (isMobile ? '90vw' : '90vw')};
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'column')};
  background-color: white;
  -webkit-box-shadow: 0 15px 51px 6px rgba(69, 69, 69, 1);
  -moz-box-shadow: 0 15px 51px 6px rgba(69, 69, 69, 1);
  box-shadow: 0 15px 51px 6px rgba(69, 69, 69, 1);
  border-radius: 6px;
  margin: ${({ isMobile }) => (isMobile ? '40px 10px' : '70px')};
  padding: 30px;
  h3 {
    text-align: center;
  }
`;

const TilesWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;

const StyledParagraph = styled(Paragraph)`
  text-align: ${({ isMobile }) => (isMobile ? 'left' : 'justify')};
`;

const AboutElement = ({ theme }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });

  return (
    <AboutWrapper isMobile={isMobile}>
      <TilesWrapper isMobile={isMobile}>
        <AboutTile icon={house} title="kompleksowe prace" />
        <AboutTile heading title="Lat doświadczenia" />
        <AboutTile icon={law} title="UCZCIWOŚĆ I RZETELNOŚĆ" />
      </TilesWrapper>
      <StyledParagraph isMobile={isMobile}>
        <b>ZBRM ZAKŁAD BUDOWLANY MAREK STRUŻYK</b> - jest firmą, która zajmuje się kompleksowymi
        pracami budowlanymi, remontowymi, wykończeniowymi. Jesteśmy na rynku od ponad 30 lat.
        Zapewniamy rzetelną, elastyczną i sprawną obsługę naszych klientów. Oprócz doświadczenia
        posiadamy dużą wiedzę i praktykę. Służymy rownież radą w doborze materiałów i
        zagospodarowaniu przestrzeni. Nasi klienci to zarówno osoby prywatne, liczne
        przedsiębiorstwa, jak i instytucje publiczne. Równocześnie zdajemy sobie sprawę z tego, iż
        chcąc utrzymać naszą pozycje na rynku konieczna jest bezwzględna uczciwość oraz dbałość o
        wysoką jakość naszych prac.
      </StyledParagraph>
      <h3>ZAPRASZAMY DO WSPÓŁPRACY</h3>
    </AboutWrapper>
  );
};

export default withTheme(AboutElement);
