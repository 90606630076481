import React from 'react';
import styled from 'styled-components';
import SubHeading from '../../../components/atoms/SubHeading/SubHeading';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const StyledOwner = styled(SubHeading)`
  margin: 0;
  font-size: ${({ isMobile }) => isMobile && 'calc(2.8rem + 3.125vw)'};
  text-transform: uppercase;
`;

const StyledHeading = styled(SubHeading)`
  margin: 0;
  color: ${({ theme }) => theme.yellow};
  font-size: ${({ isMobile }) => isMobile && 'calc(2rem + 3.125vw)'};
  line-height: ${({ isMobile }) => isMobile && '40px'};
  text-align: center;
  text-transform: uppercase;
`;

const HeroLogoBlock = ({ isMobile }) => (
  <StyledWrapper>
    <StyledHeading isMobile={isMobile}>Zakład budowlany</StyledHeading>
    {isMobile ? (
      <>
        <StyledHeading isMobile={isMobile}>remontowo</StyledHeading>
        <StyledHeading isMobile={isMobile}>montażowy</StyledHeading>
      </>
    ) : (
      <StyledHeading isMobile={isMobile}>remontowo montażowy</StyledHeading>
    )}{' '}
    <StyledOwner isMobile={isMobile}>Marek Strużyk</StyledOwner>
  </StyledWrapper>
);

export default HeroLogoBlock;
