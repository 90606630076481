import {
  PROJECTS_PENDING,
  PROJECTS_FAILURE,
  PROJECTS_UPDATE_IMAGE,
  PROJECTS_SUCCESS,
} from 'redux/actions/projects/projectsActions';

const initialState = {
  pending: false,
  error: false,
  errorMessage: null,
  projects: {},
};

const projects = (state = initialState, action) => {
  switch (action.type) {
    default: {
      return state;
    }

    case PROJECTS_PENDING: {
      return {
        ...state,
        error: false,
        pending: true,
        projects: {}
      };
    }

    case PROJECTS_FAILURE: {
      return {
        ...state,
        pending: false,
        error: true,
        projects: {},
        errorMessage: action.payload
      };
    }

    case PROJECTS_UPDATE_IMAGE: {
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.payload.projectName]: typeof state.projects[action.payload.projectName] === 'object'
            ? [...state.projects[action.payload.projectName], action.payload.image] : [action.payload.image],
        },
      };
    }

    case PROJECTS_SUCCESS: {
      return {
        ...state,
        pending: false,
        error: false,
        uploaded: true,
      };
    }
  }
};

export const getProjects = state => state.projects.projects;
export const getPending = state => state.projects.pending;
export const getError = state => state.projects.error;

export default projects;