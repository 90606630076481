import React from 'react';
import styled from 'styled-components';

const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.font.small};
  font-size: ${({ theme }) => theme.font.mediumSize};
  color: ${({ light, theme }) => (light ? 'white' : theme.darkGrey)};
  font-weight: ${({ light, theme }) => light && theme.font.medium};
  line-height: 30px;
  text-align: justify;
  text-justify: inter-character;
`;

export default Paragraph;
