import React from 'react';
import styled, { withTheme } from 'styled-components';
import ContactForm from '../../molecules/ContactForm/ContactForm';
import ContactInfo from '../../molecules/ContactInfo/ContactInfo';
import useMedia from 'use-media';
import SectionHeading from '../../molecules/SectionHeading/SectionHeading';

const ContactWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ContactSection = ({ theme }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });
  return (
    <>
      <StyledWrapper id={'kontakt'}>
        <SectionHeading>kontakt</SectionHeading>
      </StyledWrapper>
      <ContactWrapper isMobile={isMobile}>
        <ContactInfo />
        <ContactForm />
      </ContactWrapper>
    </>
  );
};
export default withTheme(ContactSection);
