import React, { useState } from 'react';
import styled, { withTheme } from 'styled-components';
import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import Heading from 'components/atoms/Heading/Heading';
import useMedia from 'use-media';

const ContactWrapper = styled.form`
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: ${({ theme }) => theme.darkGrey};
  padding: 15px;
  h1 {
    color: white;
    text-transform: uppercase;
  }
`;

const ContactForm = ({ theme }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });

  const initialForm = {
    name: '',
    surname: '',
    email: '',
    content: '',
  };

  const [formState, setFormState] = useState(initialForm);
  const [status, setStatus] = useState(null);

  const handleInputChange = (e) => {
    const target = e.target;

    setFormState({
      ...formState,
      [target.name]: target.value,
    });
  };

  const submitForm = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus('SUCCESS');
      } else {
        setStatus('ERROR');
      }
    };
    xhr.send(data);
  };

  return (
    <ContactWrapper
      onSubmit={submitForm}
      action="https://formspree.io/mrgybygj"
      method="POST"
      isMobile={isMobile}
    >
      <Heading light>Formularz</Heading>
      {[
        {
          title: 'Imię',
          name: 'name',
        },
        {
          title: 'Nazwisko',
          name: 'surname',
        },
        {
          title: 'Email',
          name: 'email',
        },
      ].map((item) => (
        <Input
          isMobile={isMobile}
          name={item.name}
          title={item.title}
          value={formState[item.name]}
          onChange={handleInputChange}
        />
      ))}
      <Input
        isMobile={isMobile}
        area
        title="Treść"
        name={'content'}
        value={formState.content}
        onChange={handleInputChange}
      />
      <Button>
        Wyślij
        {/* <input type={'submit'} value="Wyślij" /> */}
      </Button>
    </ContactWrapper>
  );
};

export default withTheme(ContactForm);
