import { createGlobalStyle, withTheme } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.5;
    font-family: 'Montserrat', sans-serif;
    overflow-y:scroll;
  }
  
  html {
    font-size: 62.5%;
  }
`;

export default withTheme(GlobalStyle);
