import React from 'react';
import styled, { withTheme } from 'styled-components';
import useMedia from 'use-media';

const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
`;

const Footer = ({ theme }) => {
  const isMobile = useMedia({ maxWidth: theme.media.mobile });
  return <StyledWrapper>Wszelkie prawa zastrzeżone ZBRM Marek Strużyk©</StyledWrapper>;
};

export default withTheme(Footer);
