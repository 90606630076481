import firebase from 'config/firebase';
import {
  projectsFailure,
  projectsPending,
  projectsSuccess,
  projectsUpdateImage,
} from './projectsActions';
import getUrl from './getUrl';

const storage = firebase.storage();

export function fetchProjects() {
  return (dispatch) => {
    dispatch(projectsPending());

    const projectsRef = storage.ref('projects');

    projectsRef
      .listAll()
      .then((snapshot) => {
        snapshot.prefixes.forEach((project) => {
          const projectName = project.name;

          project.listAll().then((snapshot) => {
            snapshot.items.forEach((image) => {
              getUrl(image).then(url => dispatch(projectsUpdateImage(url, projectName)));
            });
            dispatch(projectsSuccess());
          });
        });
      })
      .catch((err) => dispatch(projectsFailure(err)));
  };
}
